import React, { useState } from 'react';
import Swal from 'sweetalert2';
import authorizePostRequestWOT from '../api/authorizePostRequestWOT';
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'

const Contact = () => {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [subject, setSubject] = useState('')
  const [message, setMessage] = useState('')
  const [loading, setLoading] = useState(false);

  const onChangeName = (e) => {
    setName(e.target.value);
  };
  const onChangeEmail = (e) => {
    setEmail(e.target.value);
  };
  const onChangeSubject = (e) => {
    setSubject(e.target.value);
  };
  const onChangeMessage = (e) => {
    setMessage(e.target.value);
  };
  const handleSubmit = async (e) => {
    e.preventDefault()
    setLoading(true);
    const dt = {
      name,
      email,
      subject,
      message
    }
    await authorizePostRequestWOT('client/contact', dt)
      .then((response) => {
        if (response.success === true) {
          Swal.fire({
            position: 'top-end',
            toast: true,
            width: 600,
            // background: '#F47E52',
            icon: 'success',
            titleText: response.message,
            showConfirmButton: false,
            timer: 2500,
          });
        } else {
          Swal.fire({
            position: 'top-end',
            toast: true,
            width: 600,
            // background: '#F47E52',
            icon: 'error',
            titleText: response.message,
            showConfirmButton: false,
            timer: 2500,
          });
        }
        setName('')
        setEmail('')
        setSubject('')
        setMessage('')
      })
      .catch((err) => {
        Swal.fire({
          position: 'top-end',
          toast: true,
          width: 600,
          // background: '#F47E52',
          icon: 'error',
          titleText: err.message,
          showConfirmButton: false,
          timer: 2500,
        });
      });
    setLoading(false);
  }

  return (
    <>
      <Navbar />

      {/* intro */}
      <div class="container-fluid page-header mb-5 wow fadeIn" data-wow-delay="0.1s">
        <div class="container text-center">
          <h1 class="display-4 text-white animated slideInDown mb-4">Get in Touch</h1>
          {/* <nav aria-label="breadcrumb animated slideInDown">
            <ol class="breadcrumb justify-content-center mb-0">
              <li class="breadcrumb-item"><a class="text-white" href="#">Home</a></li>
              <li class="breadcrumb-item"><a class="text-white" href="#">Problem Occured</a></li>
              <li class="breadcrumb-item text-primary active" aria-current="page">{`Error`}</li>
            </ol>
          </nav> */}
        </div>
      </div>
      {/* intro */}

      {/* contact */}
      <div class="container-xxl py-5">
        <div class="container">
          <div class="row g-5">
            <div class="col-lg-12 wow fadeIn d-flex align-items-center justify-content-center" data-wow-delay="0.1s">
              <div class="col-lg-6">
                <h1 class="display-6 mb-5">If You Have Any Query, Please Contact Us</h1>

                <form method='post' onSubmit={handleSubmit}>
                  <div class="row g-3">
                    <div class="col-md-6">
                      <div class="form-floating">
                        <input value={name}
                          onChange={onChangeName} type="text" name="name" class="form-control" id="name" placeholder="Your Name" required />
                        <label for="name">Your Name</label>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-floating">
                        <input value={email}
                          onChange={onChangeEmail} type="email" name="email" class="form-control" id="email" placeholder="Your Email" required />
                        <label for="email">Your Email</label>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="form-floating">
                        <input value={subject}
                          onChange={onChangeSubject} type="text" name="subject" class="form-control" id="subject" placeholder="Subject" required />
                        <label for="subject">Subject</label>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="form-floating">
                        <textarea value={message}
                          onChange={onChangeMessage} name="message" class="form-control" placeholder="Leave a message here" id="message" style={{ height: 100 }} required></textarea>
                        <label for="message">Message</label>
                      </div>
                    </div>
                    <div class="col-12">
                      {loading ? (
                        <div id="spinner"
                          class="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center">
                          <div class="spinner-grow text-primary" role="status"></div>
                        </div>
                      ) : (
                        <button type='submit' class="btn btn-primary py-2 px-3 me-3">
                          Send Message
                          <div class="d-inline-flex btn-sm-square bg-white text-primary rounded-circle ms-2">
                            <i class="fa fa-arrow-right"></i>
                          </div>
                        </button>
                      )}

                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* contact */}

      <Footer />

    </>
  )
}

export default Contact
