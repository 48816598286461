import { NavLink, Link } from "react-router-dom";
import logo from '../assets/logo.png'
import rapehurts from '../assets/rapehurts.png'

const Navbar = ({page}) => {
  return (
    <div class="container-fluid fixed-top px-0 wow fadeIn" data-wow-delay="0.1s">
      <div class="top-bar text-white-50 row gx-0 align-items-center d-none d-lg-flex">
        <div class="col-lg-6 px-5 text-start">
          <small><i class="fa fa-map-marker-alt me-2"></i>Kyabazinga Way 2nd Lane Plot 3, Ug</small>
          <small class="ms-4"><i class="fa fa-envelope me-2"></i>rapehurtsuganda@gmail.com</small>
        </div>
        <div class="col-lg-6 px-5 text-end">
          <small>Follow us:</small>
          <Link class="text-white-50 ms-3" to="https://www.facebook.com/RapeHurts.org"><i class="fab fa-facebook-f"></i></Link>
          <Link class="text-white-50 ms-3" to="https://twitter.com/rapehurtsf"><i class="fab fa-twitter"></i></Link>
          <Link class="text-white-50 ms-3" to="https://www.linkedin.com/company/rape-hurts-foundation/"><i class="fab fa-linkedin-in"></i></Link>
          <Link class="text-white-50 ms-3" to="https://www.instagram.com/rapehurtsfoundation/"><i class="fab fa-instagram"></i></Link>
        </div>
      </div>

      <nav class="navbar navbar-expand-lg navbar-dark py-lg-0 px-lg-5 wow fadeIn" data-wow-delay="0.1s">
        <Link to="/" class="navbar-brand ms-4 ms-lg-0 ">
          <img src={logo} width='70' />
          {/* <h1 class="fw-bold text-primary ml-3 mt-0 mb-0">Rape<span class="text-white"> Hurts </span></h1> */}
        </Link>
        <button type="button" class="navbar-toggler me-4" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarCollapse">
          <div class="navbar-nav ms-auto p-4 p-lg-0">
            <NavLink to="/" className={({ isActive, isPending }) => isActive ? "nav-item nav-link active" : "nav-item nav-link" }>Home</NavLink>
            <div class="nav-item dropdown">
              <a href="#" class={page === 'story' || page === 'about' ? "nav-link dropdown-toggle active" : "nav-link dropdown-toggle"} data-bs-toggle="dropdown">About</a>
              <div class="dropdown-menu m-0">
                <NavLink to="/about" className={({ isActive, isPending }) => isActive ? "dropdown-item active" : "dropdown-item"}>About Us</NavLink>
                <NavLink to="/story/hellen" className={({ isActive, isPending }) => isActive ? "dropdown-item active" : "dropdown-item"}>Hellen's Story</NavLink>
              </div>
            </div>
            
            <NavLink to="/campaigns" className={({ isActive, isPending }) => isActive ? "nav-item nav-link active" : "nav-item nav-link"}>Campaigns</NavLink>
            <NavLink to="/programs" className={({ isActive, isPending }) => isActive ? "nav-item nav-link active" : "nav-item nav-link"}>Program Areas</NavLink>
            <NavLink to="/contact" className={({ isActive, isPending }) => isActive ? "nav-item nav-link active" : "nav-item nav-link"}>Contact</NavLink>
          </div>
          <div class="d-none d-lg-flex ms-2">
            <NavLink to="/donate" className={({ isActive, isPending }) => isActive ? "btn btn-outline-primary py-2 px-3 active" : "btn btn-outline-primary py-2 px-3"}>
              Donate Now
              <div class="d-inline-flex btn-sm-square bg-white text-primary rounded-circle ms-2">
                <i class="fa fa-arrow-right"></i>
              </div>
            </NavLink>
          </div>
        </div>
      </nav>
    </div>
  )
}

export default Navbar
