import Footer from '../components/Footer'
import Navbar from '../components/Navbar'

const Donate = () => {

  return (
    <>
      <Navbar />

      {/* intro */}
      <div class="container-fluid page-header mb-5 wow fadeIn" data-wow-delay="0.1s">
        <div class="container text-center">
          <h1 class="display-4 text-white animated slideInDown mb-4">Be a part of problem solvers</h1>
        </div>
      </div>
      {/* intro */}

      {/* donate */}
      <div class="container-fluid py-5">
        <div class="container">
          <div class="row g-5 align-items-center">
            <div class="col-lg-6 wow fadeIn" data-wow-delay="0.1s">
              <h1 class="display-6 mb-5">Thanks For The Love & Support You Show </h1>
              <p class="mb-0">
                Please be aware that all PayPal donations will be received by our U.S. partner organization, the Solar Electric Light Fund (SELF). Funds will be used to support RHF’s operating expenses to enable us to participate in our joint project efforts
              </p>
            </div>
            <div class="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
              <div class="h-100 bg-secondary p-5">
                <form>
                  <div class="row g-3">
                    <div class="col-12">
                      <div class="form-floating">
                        <input type="text" class="form-control bg-light border-0" id="name" placeholder="Your Name" />
                        <label for="name">Your Names</label>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="form-floating">
                        <input type="email" class="form-control bg-light border-0" id="email" placeholder="Your Email" />
                        <label for="email">Your Email</label>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="btn-group d-flex justify-content-around">
                        <input type="radio" class="btn-check" name="btnradio" id="btnradio1" />
                        <label class="btn btn-light py-3" for="btnradio1">$10</label>

                        <input type="radio" class="btn-check" name="btnradio" id="btnradio2" />
                        <label class="btn btn-light py-3" for="btnradio2">$100</label>

                        <input type="radio" class="btn-check" name="btnradio" id="btnradio3" />
                        <label class="btn btn-light py-3" for="btnradio3">$1000</label>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="form-floating">
                        <input type="email" class="form-control bg-light border-0" id="email" placeholder="Your Email" />
                        <label for="email">Your Donation Amount</label>
                      </div>
                    </div>
                    <div class="col-12">
                      <button type="button" onClick={()=> {
                        window.location.href = 'https://www.paypal.com/donate/?hosted_button_id=YSV3G9JYRQSNG'
                      }} class="btn btn-primary px-5" style={{ height: 60 }}>
                        Donate Now
                        <div class="d-inline-flex btn-sm-square bg-white text-primary rounded-circle ms-2">
                          <i class="fa fa-arrow-right"></i>
                        </div>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* donate */}

      <Footer />
    </>
  )
}

export default Donate
