import React, { useState } from 'react';
import Swal from 'sweetalert2';
import { Link } from "react-router-dom";
import authorizePostRequestWOT from "../api/authorizePostRequestWOT";
import rapehurts from "../assets/rapehurts.png"

const Footer = () => {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('')
  const [msg, setMsg] = useState('')

  const onChangeEmail = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault()
    setLoading(true);
    setMsg('')
    const dt = {
      email,
    }
    await authorizePostRequestWOT('client/newsletter', dt)
      .then((response) => {
          Swal.fire({
            position: 'top-end',
            toast: true,
            width: 600,
            // background: '#F47E52',
            icon: 'success',
            titleText: response.message,
            showConfirmButton: false,
            timer: 2500,
          });
        setMsg(response.message)
        setEmail('')
      })
      .catch((err) => {
        setMsg(err.message)
        Swal.fire({
          position: 'top-end',
          toast: true,
          width: 600,
          // background: '#F47E52',
          icon: 'error',
          titleText: err.message,
          showConfirmButton: false,
          timer: 2500,
        });
      });
    setLoading(false);
  }

  return (
    <div className="container-fluid bg-dark text-white-50 footer mt-5 pt-5 wow fadeIn" data-wow-delay="0.1s">
      <div className="container py-5">
        <div className="row g-5">
          <div className="col-lg-3 col-md-6">
            <img src={rapehurts} width='180' />
            {/* <h1 className="fw-bold text-primary mb-4">Rape<span className="text-white"> Hurts</span></h1> */}
            <p> For support of Treatment, Prevention, Care and Education</p>
            <div className="d-flex pt-2">
              <Link className="btn btn-square me-1" to="https://www.facebook.com/RapeHurts.org"><i className="fab fa-facebook-f"></i></Link>
              <Link className="btn btn-square me-1" to="https://twitter.com/rapehurtsf"><i className="fab fa-twitter"></i></Link>
              <Link className="btn btn-square me-1" to="https://www.linkedin.com/company/rape-hurts-foundation/"><i className="fab fa-linkedin-in"></i></Link>
              <Link className="btn btn-square me-1" to="https://www.instagram.com/rapehurtsfoundation/"><i className="fab fa-instagram"></i></Link>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <h5 className="text-light mb-4">Address</h5>
            <p><i className="fa fa-map-marker-alt me-3"></i>Kyabazinga Way, Plot 3, Ug</p>
            <p><i className="fa fa-phone-alt me-3"></i>+25678 279 6296</p>
            <p><i className="fa fa-envelope me-3"></i>rapehurtsuganda@gmail.com</p>
          </div>
          <div className="col-lg-3 col-md-6">
            <h5 className="text-light mb-4">Quick Links</h5>
            {/* <Link className="btn btn-link" to="/blog">Our Blog</Link> */}
            <Link className="btn btn-link" to="/contact">Contact Us</Link>
            <Link className="btn btn-link" to="/terms">Terms & Condition</Link>
            <Link className="btn btn-link" to="/privacy-policy">Privacy Policy</Link>
          </div>
          <div className="col-lg-3 col-md-6">
            <h5 className="text-light mb-4">Newsletter</h5>
            <p>We promise never to spam.</p>
            {loading ? (
              <>
                <div className="loader m-5 text-center">
                  <div className="spinner-border text-primary" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              </>
            ) : (
            <form method='post' onSubmit={handleSubmit}>
              <div className="position-relative mx-auto" style={{maxWidth: 400}}>
                <input value={email} onChange={onChangeEmail} className="form-control bg-transparent w-100 py-3 ps-4 pe-5" type="text" placeholder="Your email" required />
                <button type="submit" className="btn btn-primary py-2 position-absolute top-0 end-0 mt-2 me-2">SignUp</button>
              </div>
            </form>
            )}
            {msg && (
              <p className="text-primary">{msg}</p>
            )}
          </div>
        </div>
      </div>
      <div className="container-fluid copyright">
        <div className="container">
          <div className="row">
            <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
              &copy; <Link to="/">Rape Hurts Foundation</Link>, All Right Reserved.
            </div>
            <div style={{display: 'none'}} className="col-md-6 text-center text-md-end">
              Designed By <a href="https://htmlcodex.com">HTML Codex</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
